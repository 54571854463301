import { BaseModel } from 'modules/core/models/base-model';

export class Clause extends BaseModel {

    order       = null;
    name        = null;
    parent_id   = null;
    editable_id = null;
    status_id   = null;

}
