import { BaseModel } from 'modules/core/models/base-model';

export class ClauseApplicableRegulation extends BaseModel {

    clause_id     = null;
    point         = null;
    regulations   = [];
    clause_types  = [];

}
