import { BaseModel } from 'modules/core/models/base-model';

export class LiftType extends BaseModel {

    name                   = null;
    type_id                = null;
    status_id              = null;
    applicable_regulations = [];

}
