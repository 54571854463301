import { BaseModel } from 'modules/core/models/base-model';

const AWAITING_VALIDATION = 1;
const APPROVED            = 2;
const REJECTED            = 3;
const SEALED              = 4;
const DISASSEMBLED        = 5;

export class LiftStatus extends BaseModel {

    static get AWAITING_VALIDATION() {
        return AWAITING_VALIDATION;
    }

    static get APPROVED() {
        return APPROVED;
    }

    static get REJECTED() {
        return REJECTED;
    }

    static get SEALED() {
        return SEALED;
    }

    static get DISASSEMBLED() {
        return DISASSEMBLED;
    }

}
