import { BaseModel } from 'modules/core/models/base-model';

export class Setting extends BaseModel {

    inspection_periodicity                         = null;
    inspection_request_notification                = null;
    inspection_request_deadline                    = null;
    inspection_request_deadline_after_notification = null;
    inspection_execution_deadline                  = null;
    scheduling_confirmation_deadline               = null;

}
