import { BaseModel } from 'modules/core/models/base-model';

const MANAGING_ENTITY     = 1;
const CONTROLLING_ENTITY  = 2;
const INSPECTING_ENTITY   = 3;
const MAINTENANCE_COMPANY = 4;
const INSTALLING_COMPANY  = 5;
const OWNER               = 6;

export class EntityType extends BaseModel {

    static get MANAGING_ENTITY() {
        return MANAGING_ENTITY;
    }

    static get CONTROLLING_ENTITY() {
        return CONTROLLING_ENTITY;
    }

    static get INSPECTING_ENTITY() {
        return INSPECTING_ENTITY;
    }

    static get MAINTENANCE_COMPANY() {
        return MAINTENANCE_COMPANY;
    }

    static get INSTALLING_COMPANY() {
        return INSTALLING_COMPANY;
    }

    static get OWNER() {
        return OWNER;
    }

}
