import { BaseModel } from 'modules/core/models/base-model';

const NON_PAYMENT = 1;

export class CommunicationType extends BaseModel {

    static get NON_PAYMENT() {
        return NON_PAYMENT;
    }

}
