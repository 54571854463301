import { BaseModel } from 'modules/core/models/base-model';

export class InspectionPeriodicity extends BaseModel {

    lift_type_id     = null;
    building_type_id = null;
    periodicity      = null;
    building_types   = [];

}
